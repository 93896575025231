<template>
  <div class="component-wrap">
    <div class="tabs">
      <a-tabs v-model:activeKey="currentTab">
        <!-- 个人信息 -->
        <a-tab-pane :key="1" :tab="$t('login.personal_information')">
          <a-form
            class="form-box"
            name="custom-validation"
            ref="formRef_userInfo"
            size="large"
            :model="userInfo"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 11 }"
            @finish="saveUserInfo"
          >
            <!-- 头像 -->
            <a-form-item :label="$t('login.avatar')" :colon="false" name="file">
              <div class="avatar-box">
                <img :src="imageUrl" />
                <input
                  class="up-input"
                  ref="uploadInput"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                  @change="selectFile"
                />
                <div class="edit">{{ $t("cm_modify") }}</div>
                <!-- 修改 -->
              </div>
            </a-form-item>
            <!-- 姓名 -->
            <a-form-item
              :label="$t('login.name')"
              :colon="false"
              name="realName"
            >
              <OpenData
                type="userName"
                :openid="realName"
                v-if="
                  platformConfig.platform == 26 || platformConfig.platform == 31
                "
              />
              <a-input v-model:value="realName" size="large" v-else disabled />
            </a-form-item>
            <!-- 性别 -->
            <a-form-item
              :label="$t('teacher.sex')"
              :colon="false"
              name="gender"
            >
              <a-radio-group v-model:value="gender">
                <a-radio :value="1">{{ $t("teacher.man_woman", 1) }}</a-radio>
                <!-- 男 -->
                <a-radio :value="2">{{ $t("teacher.man_woman", 2) }}</a-radio>
                <!-- 女 -->
              </a-radio-group>
            </a-form-item>
            <!-- 出生日期 -->
            <a-form-item
              :label="$t('login.birthday')"
              :colon="false"
              name="birthday"
            >
              <a-date-picker
                v-model:value="birthday"
                type="date"
                size="large"
                :placeholder="$t('login.birthday_p')"
                format="YYYY-MM-DD"
                @change="selectDate"
                style="width: 100%"
              />
              <!-- 请选择出生日期 -->
            </a-form-item>
            <!-- 手机号 -->
            <a-form-item
              :label="$t('login.mobile')"
              :colon="false"
              name="mobile"
              :rules="[{ validator: validatorMobile }]"
            >
              <a-input
                v-model:value="userInfo.mobile"
                size="large"
                :placeholder="$t('login.mobile_p')"
              />
              <!-- 请输入手机号 -->
            </a-form-item>
            <!-- 座机 -->
            <a-form-item
              :label="$t('login.landline')"
              :colon="false"
              name="tel"
              :rules="[{ validator: validatorTel }]"
            >
              <a-input
                v-model:value="userInfo.tel"
                size="large"
                :placeholder="$t('login.landline_p')"
              />
              <!-- 请输入座机 -->
            </a-form-item>
            <!-- 邮箱 -->
            <a-form-item
              :label="$t('login.email')"
              :colon="false"
              name="email"
              :rules="[{ validator: validatorEmail }]"
            >
              <a-input
                v-model:value="userInfo.email"
                size="large"
                :placeholder="$t('login.email_p')"
              />
              <!-- 请输入邮箱 -->
            </a-form-item>
            <div class="btns">
              <a-button
                class="btn"
                type="primary"
                html-type="submit"
                :loading="submitting"
                >{{ $t("login.save_submit") }}</a-button
              >
              <!-- 保存并提交 -->
            </div>
          </a-form>
        </a-tab-pane>
        <!-- 修改密码 -->
        <a-tab-pane
          :key="2"
          :tab="$t('CM_ModifyPassword')"
          v-if="hasPwd && !ISHTSY"
        >
          <div class="password-wrap">
            <div class="password-rule" v-if="companyInfo.useStrongPass == 1">
              <h5>{{ $t("login.password_rules") }}</h5>
              <p>
                <CaretRightOutlined class="icon" />{{
                  $t("login.strong_password_rules_1")
                }}
              </p>
              <p>
                <CaretRightOutlined class="icon" />{{
                  $t("login.strong_password_rules_2")
                }}
              </p>
              <p>
                <CaretRightOutlined class="icon" />{{
                  $t("login.strong_password_rules_3")
                }}
              </p>
              <p>
                <CaretRightOutlined class="icon" />{{
                  $t("login.strong_password_rules_4")
                }}
              </p>
              <p>
                <CaretRightOutlined class="icon" />{{
                  $t("login.strong_password_rules_5")
                }}
              </p>
            </div>
            <a-form
              class="password-form"
              ref="formRef"
              :model="formState"
              :rules="rules"
              layout="vertical"
              @finish="updatePassword"
            >
              <h5 v-if="companyInfo.useStrongPass == 1">{{ $t("CM_ModifyPassword") }}</h5>
              <!-- 原密码 -->
              <a-form-item
                has-feedback
                :label="$t('login.old_password')"
                :colon="false"
                name="oldpass"
              >
                <a-input-password
                  v-model:value="formState.oldpass"
                  type="password"
                  size="large"
                  autocomplete="off"
                />
              </a-form-item>
              <!-- 新密码 -->
              <a-form-item
                has-feedback
                :label="$t('login.new_password')"
                :colon="false"
                name="newpass"
              >
                <a-input-password
                  v-model:value="formState.newpass"
                  type="password"
                  size="large"
                  autocomplete="off"
                />
              </a-form-item>
              <!-- 确认密码 -->
              <a-form-item
                has-feedback
                :label="$t('login.confirm_password')"
                :colon="false"
                name="newpass2"
              >
                <a-input-password
                  v-model:value="formState.newpass2"
                  type="password"
                  size="large"
                  autocomplete="off"
                />
              </a-form-item>
              <div class="btns">
                <a-button
                  class="btn"
                  type="primary"
                  html-type="submit"
                  :loading="confirmLoading"
                  >{{ $t("login.ok_to_modify") }}</a-button
                >
                <!-- 确定修改 -->
              </div>
            </a-form>
          </div>
        </a-tab-pane>
        <!-- 人脸采集 -->
        <a-tab-pane v-if="showFace" :key="3" :tab="$t('XB_FaceSampling')">
          <div class="face-container">
            <div class="face-bg">
              <div class="center">
                <face-container>
                  <div class="photo" v-if="faceImagePath && status !== 2">
                    <img :src="faceImagePath" />
                  </div>
                  <face-index ref="faceIndexRef" @on-send="handleSend" />
                  <template #action>
                    <!-- 开始采样 -->
                    <a-button
                      block
                      size="large"
                      type="primary"
                      @click="start"
                      v-if="status === 1"
                      >{{ $t("StartSampling") }}</a-button
                    >
                    <!-- 确定 -->
                    <a-button
                      v-else-if="status === 2"
                      block
                      size="large"
                      type="primary"
                      :loading="loading"
                      :disabled="loading || !isSubmit"
                      @click="confirm"
                      >{{ $t("CM_Confirm") }}</a-button
                    >
                    <!-- 重新采样 -->
                    <a-button
                      block
                      size="large"
                      type="primary"
                      :disabled="faceStatus == 2"
                      v-else-if="status === 3"
                      @click="start"
                      >{{ $t("Resample") }}</a-button
                    >
                    <!-- 状态 -->
                    <div
                      v-if="faceStatus"
                      class="face-status"
                      :class="`face-status-${faceStatus}`"
                    >
                      <div class="icon" v-if="faceStatus == 2"></div>
                      <div class="icon" v-else-if="faceStatus == 4">
                        <CloseCircleOutlined />
                      </div>
                      <div class="icon" v-else>
                        <CheckCircleOutlined />
                      </div>
                      <!-- 待审核 -->
                      <!-- 已驳回 -->
                      <!-- 已通过 -->
                      <span class="txt">
                        {{
                          faceStatus == 2
                            ? $t("Pub_Lab_PendingAudit")
                            : faceStatus == 4
                            ? $t("teacher.rejected")
                            : $t("Pub_Passed")
                        }}
                      </span>
                    </div>
                  </template>
                </face-container>
              </div>
            </div>
            <div class="face-tip">
              <a-divider>
                <!-- 头像采集提醒 -->
                <span class="tip-title">{{ $t("AvatarCaptureAlert") }}</span>
              </a-divider>
              <div class="tip-item">1.{{ $t("AvatarCaptureAlertTip1") }}</div>
              <div class="tip-item">2.{{ $t("AvatarCaptureAlertTip2") }}</div>
              <div class="tip-item">3.{{ $t("AvatarCaptureAlertTip3") }}</div>
              <div class="tip-item">4.{{ $t("AvatarCaptureAlertTip4") }}</div>
              <div class="tip-item">5.{{ $t("AvatarCaptureAlertTip5") }}</div>
            </div>
          </div>
        </a-tab-pane>
        <template #tabBarExtraContent>
          <a-button type="primary" @click="back">{{ $t("go_back") }}</a-button>
        </template>
      </a-tabs>
      <div
        class="one-page-back"
        v-if="[1, 2, 3].includes(companyInfo.mobileModule)"
        @click="back"
      >
        <RollbackOutlined /> {{ $t("go_back") }}
        <!--  -->
      </div>
    </div>
    <!-- 上传头像 -->
    <a-modal
      v-model:visible="showDialog"
      :title="$t('login.upload_avatar')"
      :maskClosable="false"
    >
      <VuePictureCropper
        :boxStyle="{
          width: '400px',
          height: '400px',
          backgroundColor: '#f8f8f8',
          margin: 'auto',
        }"
        :img="pic"
        :options="{
          viewMode: 1,
          dragMode: 'move',
          aspectRatio: 1,
          cropBoxResizable: false,
        }"
        :presetMode="{
          mode: 'fixedSize',
          width: 300,
          height: 300,
        }"
      />
      <template #footer>
        <a-button @click="showDialog = false">{{ $t("CM_Cancel") }}</a-button>
        <!-- 取消 -->
        <a-button type="primary" @click="getResult">{{
          $t("teacher.crop")
        }}</a-button>
        <!-- 裁切 -->
      </template>
    </a-modal>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, getCurrentInstance, reactive, ref, toRefs } from "vue";
import { uploadUrl } from "@/utils/tools";
import checkPassword from "@/utils/checkPassword";
import {
  validatorMobile,
  validatorEmail,
  validatorTel,
} from "@/utils/formRules";
import { userInfo, userInfoEdit, pwdEdit } from "@/api/user";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import request from "@/utils/request";
import OpenData from "@/components/OpenData.vue";
import faceIndex from "@/components/faceCapture/index.vue";
import faceContainer from "@/components/faceCapture/container.vue";
import { submitOpenFace } from "@/api/user";
export default {
  components: {
    VuePictureCropper,
    OpenData,
    faceContainer,
    faceIndex,
  },
  setup() {
    const { t: $t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const formRef_userInfo = ref();
    const formRef = ref();
    const formState = reactive({
      oldpass: "",
      newpass: "",
      newpass2: "",
    });
    const state = reactive({
      currentTab: Number(route.query.tab || 1),
      userInfo: {},
      platformConfig: computed(() => store.getters.platformConfig),
      companyInfo: computed(() => store.getters.companyInfo),
      fileList: [],
      loadingImg: false,
      imageUrl: "",
      realName: "",
      gender: 0,
      birthday: null,
      submitting: false,
      confirmLoading: false,
      pic: "",
      showDialog: false,
      hasPwd: false,
      faceImagePath: "",
      status: 1, // 1开始 2确定 3重新
      loading: false,
      isSubmit: false,
      faceStatus: 0, // 1 导入(已通过) 2 审核中 3 已通过 4驳回
      showFace: false,
    });

    //隐藏修改密码
    if (
      state.platformConfig.platform != 26 &&
      state.platformConfig.platform != 31 &&
      state.platformConfig.platform != 41
    ) {
      state.hasPwd = true;
    }

    const getUserInfo = () => {
      userInfo().then((res) => {
        if (res.ret == 0) {
          store.dispatch("setUserInfo", res.data);
          state.imageUrl = res.data.portrait || "";
          state.gender = res.data.gender;
          state.realName = res.data.realName;
          initSampling(res.data.faceImagePath, res.data.openfaceStatus);
          state.birthday =
            res.data.birthday > 0
              ? moment(
                  String(res.data.birthday).substring(0, 4) +
                    "-" +
                    String(res.data.birthday).substring(4, 6) +
                    "-" +
                    String(res.data.birthday).substring(6, 8)
                )
              : null;
          state.userInfo = {
            file: "",
            departmentName: res.data.departmentName || "",
            positionName: res.data.positionName || "",
            gender: 0,
            birthday: String(res.data.birthday) || "",
            mobile: res.data.mobile || "",
            tel: res.data.tel || "",
            email: res.data.email || "",
          };
        }
      });
    };

    let validateOldPass = async (rule, value) => {
      if (value === "") {
        return Promise.reject($t("login.password_p"));
        // 请输入密码
      } else {
        if (value.length < 8) {
          return Promise.reject($t("login.password_len"));
          // 密码不能少于8位
        }

        return Promise.resolve();
      }
    };
    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject($t("login.confirm_password_p"));
        // 请输入确认密码
      } else if (value !== formState.newpass) {
        return Promise.reject($t("login.not_match"));
        // 两次密码不一致
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      oldpass: [
        {
          required: true,
          validator: validateOldPass,
          trigger: "change",
        },
      ],
      newpass: [
        {
          required: true,
          message: $t("login.new_password_p"),
          // 请输入新密码
          trigger: "change",
        },
        {
          validator: checkPassword,
          trigger: "change",
        },
      ],
      newpass2: [
        {
          required: true,
          validator: validatePass2,
          trigger: "change",
        },
      ],
    };

    const updatePassword = (values) => {
      state.confirmLoading = true;
      pwdEdit({
        oldpass: proxy.$getRsaCode(formState.oldpass),
        newpass: proxy.$getRsaCode(formState.newpass),
      })
        .then((res) => {
          if (res.ret === 0) {
            proxy.$message.success($t("mine.successfully_modified"));
            formState.oldpass = "";
            formState.newpass = "";
            formState.newpass2 = "";
            formRef.value.resetFields();
          }
        })
        .finally((res) => {
          state.confirmLoading = false;
        });
    };

    const saveUserInfo = (values) => {
      state.submitting = true;
      state.userInfo.gender = state.gender || 0;
      userInfoEdit(state.userInfo)
        .then((res) => {
          if (res.ret == 0) {
            proxy.$message.success($t("Pub_Msg_SaveSuccess"));
            // 保存成功！
            getUserInfo();
          }
        })
        .finally((res) => {
          state.submitting = false;
        });
    };

    const selectDate = (date, dateString) => {
      state.userInfo.birthday = dateString;
    };

    getUserInfo();

    const uploadInput = ref(null);
    let blobURL = "";
    const selectFile = (e) => {
      blobURL = "";
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.pic = String(reader.result);
        state.showDialog = true;
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const getResult = async () => {
      const blob = await cropper.getBlob();
      const file = await cropper.getFile();
      try {
        blobURL = URL.createObjectURL(blob);
      } catch (e) {
        blobURL = "";
      }
      state.showDialog = false;
      state.imageUrl = blobURL;
      let formData = new FormData();
      formData.append("file", blob, file.name);
      request
        .post(uploadUrl + "study/me/upload/avatar", formData)
        .then((res) => {
          state.userInfo.file = res.data;
        });
    };

    const back = () => {
      router.go(-1);
    };

    const faceIndexRef = ref(null);
    const initSampling = (imgUrl, status) => {
      state.faceImagePath = imgUrl;
      state.faceStatus = status || 0;
      if (imgUrl) state.status = 3;
    };
    const start = () => {
      state.status = 2;
      faceIndexRef.value.openCamera(true);
    };
    const confirm = () => {
      faceIndexRef.value.getPhoto(submitSampling);
    };
    const handleSend = (val) => {
      state.isSubmit = val;
    };
    const submitSampling = async (formData) => {
      if (state.loading) return false;
      state.loading = true;
      const res = await submitOpenFace(formData);
      if (res.ret == 0) {
        proxy.$message.success($t("SampleSuccessful"));
        state.faceImagePath = res.data || "";
        getUserInfo();
      } else {
        proxy.$message.error($t("SampleFail"));
      }
      state.status = 3;
      faceIndexRef.value.stopCamera();
      state.loading = false;
    };
    const hasShowFace = () => {
      if (state.platformConfig.customerCode == "dfzx") {
        state.showFace = false;
      } else {
        if (
          state.platformConfig.useFacelogin === 1 ||
          state.platformConfig.useFaceConfig !== 2
        ) {
          state.showFace = true;
        } else {
          state.showFace = false;
        }
      }
    };
    hasShowFace();

    return {
      ISHTSY,
      ...toRefs(state),
      formRef_userInfo,
      formRef,
      formState,
      rules,
      saveUserInfo,
      updatePassword,
      uploadUrl,
      selectDate,
      validatorMobile,
      validatorEmail,
      validatorTel,
      uploadInput,
      selectFile,
      getResult,
      back,
      faceIndexRef,
      start,
      confirm,
      handleSend,
    };
  },
};
</script>

<style lang="less" scoped>
.ant-btn-primary {
  background: @color-theme;
  border-color: @color-theme;
}
.component-wrap {
  min-height: 720px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  padding: 14px 30px 30px 30px;
}
.avatar-box {
  width: 80px;
  height: 80px;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 0;
  overflow: hidden;
  img {
    width: 80px;
    height: 80px;
  }
  .up-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
  }
  .edit {
    // content: "修改";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }
}
.tabs {
  position: relative;
  .one-page-back {
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 18px;
    cursor: pointer;
  }
}
.face-container {
  .face-bg {
    width: 282px;
    height: 470px;
    margin: 0 auto;
    background: url("~@/assets/image/mine/face-bg.png") no-repeat;
    background-size: cover;
    .center {
      position: relative;
      left: 50%;
      top: 5%;
      transform: translateX(-50%);
      .photo {
        position: absolute;
        top: 4%;
        left: 4%;
        right: 4%;
        bottom: 4%;
        border-radius: 50%;
        overflow: hidden;
        // background-color: rgba(12, 207, 141, 0.8);
        z-index: 999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .face-tip {
    padding-bottom: 200px;
    background: url("~@/assets/image/mine/face-bottom-bg.png") no-repeat bottom;
    .tip-title {
      font-size: 14px;
      font-weight: 700;
      color: #333;
    }
    .tip-item {
      margin: 5px 0;
      font-size: 12px;
      color: #666;
    }
  }
  .face-status {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 8px;
      font-size: 20px;
    }
    .txt {
      vertical-align: 3px;
    }
  }
  .face-status-2 {
    color: #ff8a14;
    .icon {
      background: url("~@/assets/image/mine/pending.png") no-repeat;
      background-size: cover;
    }
    .txt {
      vertical-align: 5px;
    }
  }
  .face-status-1,
  .face-status-3 {
    color: #22cf33;
  }
  .face-status-4 {
    color: #ff0000;
  }
}
.password-wrap {
  .mixinFlex(space-between);
  padding-top: 12px;
  .password-rule {
    width: calc(50% + 24px);
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #f1f1f1;
    font-size: 14px;
    line-height: 1.6;
    h5 {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 12px;
      padding-bottom: 12px;
    }
    .day {
      color: red;
      margin-bottom: 12px;
    }
    p {
      color: #555;
      margin-bottom: 12px;
      .mixinFlex(flex-start);
      .icon {
        color: @color-theme;
        font-size: 12px;
        margin-right: 4px;
        padding-top: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .password-form {
    width: 50%;
    h5 {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.6;
      text-align: center;
      margin-bottom: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid #f1f1f1;
    }
    .tips {
      color: #666;
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 1.6;
    }
    ::v-deep(.ant-form) {
      padding: 0 24px;
      .ant-form-item-label > label {
        color: #666;
      }
    }
    .ant-form-item {
      margin-bottom: 26px;
    }
    .btns {
      text-align: center;
      .ant-btn {
        margin: 24px auto 0;
        padding: 14px 84px;
        height: 44px;
        line-height: 14px;
      }
    }
  }
}
</style>
